import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { animSideBarState, animContactShow } from './sidebar.animations';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss', '../common/common.scss'],
  animations: [animSideBarState, animContactShow]
})
export class SidebarComponent implements OnInit {
  state: string;

  constructor() {
    this.state = 'closed';
  }

  show() {
    // console.log('show')
    this.state = 'opened';
  }

  close(event) {
    // console.log(event)
    if (event.pointerType === 'touch') {
      this.state = 'closed';
    }

  }

  hide() {
    // console.log('hide')
    this.state = 'closed';
  }

  ngOnInit() { }



}
