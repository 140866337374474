import { Component, OnInit, HostBinding } from '@angular/core';
import { routeSlider } from '../common/animation';
import { MovementService } from '../movement.service';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { AbstractContentComponent } from '../abstract-content/abstract-content.component';

@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html',
  styleUrls: [
    './fee.component.scss', '../common/common.scss'
  ],
  animations: [routeSlider],
})
export class FeeComponent extends AbstractContentComponent implements OnInit {
  private direction: String;
  @HostBinding('@routeSlider') get routeSlider() {
    return this.direction;
  }
  constructor(movementService: MovementService, router: Router) {
    super(router, movementService);
    this.direction = movementService.getMovementDirection();
  }


  ngOnInit() { }

}
