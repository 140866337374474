import {
  trigger,
  state,
  animate,
  style,
  transition,
  AnimationTriggerMetadata
} from '@angular/animations';

export const routeSlider: AnimationTriggerMetadata = trigger('routeSlider', [
  state('void', style({ position: 'fixed', 'max-height': '100%', overflow: 'hidden' })),
  state('*', style({ position: 'fixed', 'max-height': '100%', overflow: 'auto' })),
  state('right', style({ position: 'fixed', 'max-height': '100%', overflow: 'auto' })),
  state('left', style({ position: 'fixed', 'max-height': '100%', overflow: 'auto' })),

  transition('void=>right', [
    style({ transform: 'translateX(-100%)', 'z-index': '-999999999' }),
    animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
  ]),
  transition('right=>void', [
    style({ transform: 'translateX(0%)', 'z-index': '-999999999' }),
    animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' }))
  ]),

  transition('*=>left', [
    style({ transform: 'translateX(100%)', 'z-index': '-999999999' }),
    animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
  ]),
  transition('left=>*', [
    style({ transform: 'translateX(0%)', 'z-index': '-999999999' }),
    animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
  ]),

  // transition('*=>void', [
  //   style({ transform: 'translateX(-100%)', 'z-index': '-999999999' }),
  //   animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
  // ]),
  // transition('void=>*', [
  //   style({ transform: 'translateX(0%)', 'z-index': '-999999999' }),
  //   animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' }))
  // ]),

]);
