import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import 'rxjs/add/operator/pairwise';
import { MovementService } from '../movement.service';

@Component({
  selector: 'app-abstract-content',
  templateUrl: './abstract-content.component.html',
  styleUrls: ['./abstract-content.component.scss']
})
export class AbstractContentComponent implements OnInit {
  movementService: MovementService;
  router: Router;

  constructor(router: Router, movementService: MovementService) {
    this.router = router;
    this.movementService = movementService;
  }

  onSwipe(direction: string): void {
    let url: any = this.movementService.getMoveUrl(direction);
    if (url) {
      this.router.navigateByUrl(url);
    }
  }

  ngOnInit() {
  }

}
