import {Component, OnInit, Input} from '@angular/core';
import {animTextHover, animIconHover} from './menu-item.animations';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [animTextHover, animIconHover]
})
export class MenuItemComponent implements OnInit {

  @Input() url: string;
  @Input() icon: string;
  @Input() label: string;
  status: string;

  constructor() {
    this.status = 'not'
  }

  hover() {
    // console.log('hover')
    this.status = 'over'
  }

  unhover() {
    // console.log('unhover')
    this.status = 'not'
  }

  ngOnInit() { }
  ngOnDestroy() {
  }
}
