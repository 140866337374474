import { Component } from '@angular/core';
// import { HammerInput } from 'hammerjs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import 'rxjs/add/operator/pairwise';
import { MovementService } from './movement.service';

@Component({ selector: 'app-root', templateUrl: './app.component.html', styleUrls: ['./app.component.scss'] })
export class AppComponent {
  movementDirection: string;
  router: Router;
  movementService: MovementService;

  public constructor(titleService: Title, router: Router, activatedRoute: ActivatedRoute, movementService: MovementService) {
    this.router = router;
    this.movementService = movementService;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this
          .getTitle(router.routerState, router.routerState.root)
          .join('-');
        titleService.setTitle(title);
      }
    });

  }

  onSwipe(direction: string): void {
    const url: any = this.movementService.getMoveUrl(direction);
    if (url) {
      this.router.navigateByUrl(url);
    }
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
