import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  OnChanges,
  HostListener,
  HostBinding,
} from "@angular/core";
// import { SebmGoogleMap } from '@agm/core';
import { MovementService } from "../movement.service";
import { routeSlider } from "../common/animation";
import { AbstractContentComponent } from "../abstract-content/abstract-content.component";
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  NavigationStart,
} from "@angular/router";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss", "../common/common.scss"],
  animations: [routeSlider],
})
export class ContactComponent
  extends AbstractContentComponent
  implements OnInit
{
  lat: Number = 52.409318;
  lng: Number = 16.915813;
  // @ViewChildren('contactMap') maps: QueryList<SebmGoogleMap>;

  private direction: String;
  @HostBinding("@routeSlider") get routeSlider() {
    return this.direction;
  }

  constructor(movementService: MovementService, router: Router) {
    super(router, movementService);
    this.direction = movementService.getMovementDirection();
  }

  ngOnInit() {}
}
