import { Component, OnInit } from '@angular/core';
import { animHeaderState } from './header.animations';

@Component({ selector: 'app-header', templateUrl: './header.component.html', styleUrls: ['./header.component.scss'], animations: [animHeaderState] })
export class HeaderComponent implements OnInit {
  state: string;

  constructor() {
    this.state = 'closed';
  }

  ngOnInit() { }

  show() {
    // console.log('show')
    this.state = 'opened';
  }

  hide() {
    // console.log('hide')
    this.state = 'closed';
  }

  toggle() {
    // console.log('togggle');
    // console.log(this.state);
    this.state = this.state == 'opened'
      ? 'closed'
      : 'opened';
  }

}
