import { trigger, state, style, transition, animate } from '@angular/animations';

const animIconHover = trigger('animIconHover', [
    state('over', style({ 'background-color': 'rgb(196, 183, 158)', 'color': 'white', 'border-radius': '50px' })),
    transition('not => over', animate('500ms ease-out')),
    transition('over => not', animate('250ms ease-in'))
])

const animTextHover = trigger('animTextHover', [
    state('over', style({ color: 'rgb(196, 183, 158)' })),
    transition('not => over', animate('500ms ease-out')),
    transition('over => not', animate('250ms ease-in'))
])

export { animIconHover, animTextHover }