import { trigger, state, style, transition, animate } from '@angular/animations';

const animSideBarState = trigger('animSideBarState', [
    state('opened', style({ 'max-width': '350px' })),
    state('closed', style({ 'max-width': '60px' })),
    transition('closed => opened', animate('500ms ease-in')),
    transition('opened => closed', animate('100ms ease-out'))
])

const animContactShow = trigger('animContactShow', [
    state('opened', style({ opacity: 1 })),
    state('closed', style({ opacity: 0 })),
    transition('closed => opened', animate('500ms ease-in')),
    transition('opened => closed', animate('100ms ease-out'))
])


export { animSideBarState, animContactShow }