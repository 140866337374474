import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';

@Injectable()
export class MovementService {

  public movementDirection: String = 'right';
  public currentIndex: number;
  // private router: Router;

  constructor(private router: Router) { }

  getMoveUrl(direction: String) {
    let currentIndex: number;
    this.movementDirection = direction;

    //define where we are
    for (const routeConfig of this.router.config) {
      // console.log('route')
      // console.log(routeConfig.path)
      if (routeConfig.path === this.router.url.substring(1)) {
        // console.log('found' + this.router.url)
        if (routeConfig.path.length === 0) {
          currentIndex = 1;
        } else {
          currentIndex = this.router.config.indexOf(routeConfig);
        }
      }
    }

    //get url
    if ((direction === 'right' && currentIndex > 1) || (direction === 'left' && currentIndex < this.router.config.length - 1)) {
      if (direction === 'right') {
        // console.log('navigate right to ' + this.router.config[currentIndex - 1].path);
        return ('/' + this.router.config[currentIndex - 1].path);
      } else {
        // console.log('navigate left to ' + this.router.config[currentIndex + 1].path);
        this.router.navigateByUrl('/' + this.router.config[currentIndex + 1].path);
        return ('/' + this.router.config[currentIndex + 1].path);
      }
    }
    // console.log('not allowed');
    return null;
  }

  public setMovementDirection(direction: String) {
    this.movementDirection = direction;
  }

  public getMovementDirection(): String {
    return this.movementDirection;
  }



}
