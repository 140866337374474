import { Component, OnInit } from '@angular/core';
import { Cookie } from 'ng2-cookies';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {
  public state: String;

  constructor() {
    // console.log(Cookie.getAll())
    if (Object.keys(Cookie.getAll()).indexOf('cookie-consent') >= 0 && Cookie.getAll()['cookie-consent'] === 'true') {
      this.state = 'accepted';
    } else {
      this.state = 'notaccepted';
    }
  }

  public acceptConsent() {
    // console.log('accrpting cosent')
    Cookie.set('cookie-consent', 'true', 10000);
    this.state = 'accepted';
  }

  public findOutMore() {
    window.open("http://wszystkoociasteczkach.pl/");
  }

  ngOnInit() {
  }

}
