import { RouterModule, Routes } from '@angular/router';
import { LegalComponent } from './legal/legal.component';
import { CompanyComponent } from './company/company.component';
import { FeeComponent } from './fee/fee.component';
import { IndividualComponent } from './individual/individual.component';
import { ContactComponent } from './contact/contact.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/kancelaria',
    pathMatch: 'full',
    data: {
      title: 'Kancelaria Adwokacka Michał Padewski - O Kancelarii'
    }

  }, {
    path: 'kancelaria',
    component: LegalComponent,
    data: {
      title: 'Kancelaria Adwokacka Michał Padewski - O Kancelarii'
    }

  }, {
    path: 'przedsiebiorcy',
    component: CompanyComponent,
    data: {
      title: 'Kancelaria Adwokacka Michał Padewski - Przedsiębiorcy'
    }

  }, {
    path: 'indywidualni',
    component: IndividualComponent,
    data: {
      title: 'Kancelaria Adwokacka Michał Padewski - Klienci Indywidualni'
    }
  }, {
    path: 'honorarium',
    component: FeeComponent,
    data: {
      title: 'Kancelaria Adwokacka Michał Padewski - Honorarium'
    }
  }, {
    path: 'kontakt',
    component: ContactComponent,
    data: {
      title: 'Kancelaria Adwokacka Michał Padewski - Kontakt'
    }
  },
  {
    path: '**',
    redirectTo: '/kancelaria',
    pathMatch: 'full',
    data: {
      title: 'Kancelaria Adwokacka Michał Padewski - O Kancelarii'
    }
  }
];

export { appRoutes }